.App {
  text-align: center;
}

.calendar {
  padding: 12px;
  margin: 0 auto;
  max-width: 960px;
}

.calendar .header {
  font-size: 1.7em;
}
